import '@css/components/Modal.scss';

function Modal({id, children, className}){
    return(
        <div className={"modal " + className} id={id}>
            <div className="inner-modal">
                {children}
            </div>
        </div>
    )
}

export default Modal;