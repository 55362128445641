import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { CharacterNavbar, Button, Page, Wrapper, FieldInputs, Fetch, Header, ServerCall, Form, ContinueButton } from '@files'

import '@css/pages/BackstoryPage.scss'

function BackstoryPage() {
  //const navigate = useNavigate();

  const [backstory, setBackstory] = useState( sessionStorage.getItem('backstory') ?? "Your character's backstory" );
  // For displaying a 'story saved' message
  const [savedMessageVisible, setSavedMessageVisible] = useState(false);
  const navigate = useNavigate();
  
  ['character name', 'class', 'race', 'background'].forEach((e) => {
    if(sessionStorage.getItem(e)===null) {
        navigate('/character')
    }
  })

  const characterData = {
    charName: sessionStorage.getItem('character name') ?? '',
    charRace: sessionStorage.getItem('race') ?? '',
    charLevel: sessionStorage.getItem('level') ?? 1,
    charClass: sessionStorage.getItem('class') ?? '',
    background: sessionStorage.getItem('background') ?? ''
  };

  const generateBackstory = async () => {
    // Prepare character data to send
    console.log("TRY TO POST: ", JSON.stringify(characterData))

    try {
      // make a POST request to the backend
      const response = await fetch('https://hephaestus.digital/api/character-backstory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        body: JSON.stringify(characterData),
      });

      // Parse response as JSON
      const data = await response.json();
      console.log("DATA RECEIVED: ", JSON.stringify(data))

      // Update the state with the backstory
      setBackstory(data.backstory);
      
    } catch (error) {
      console.log("ERROR FOLLOWS:")
      console.error('Error: ', error);
    }
  };

  function saveBackstory(e) {
    // save to session storage
    e.preventDefault()
    sessionStorage.setItem('backstory', backstory);
    setSavedMessageVisible(true);
    // Hide the message after 3 seconds
    setTimeout(() => {
      setSavedMessageVisible(false);
    }, 3000);
    navigate('/charimage')
  };

  return (

    <Page id="backstory">
      <Header><CharacterNavbar/></Header>
      <Wrapper>
          <a id='ReqAttrs' href='/character'>
            <Button><b>Name:</b> {characterData.charName}</Button>
            <Button><b>Class:</b> {characterData.charClass}</Button>
            <Button><b>Level:</b> {characterData.charLevel}</Button>
            <Button><b>Race:</b> {characterData.charRace}</Button>
            <Button><b>Background:</b> {characterData.background}</Button>
          </a>

        <Form onSubmit={saveBackstory}>
          {FieldInputs({name:"backstory"}, [
            //{type:"textarea", value:backstory, id:"backstory", label: "", placeholder:"Your character's backstory", rows: 20, col: 60}
            {type:"textarea", value:backstory, id:"backstory-form", label: "", onChange: (e)=>{setBackstory(e.target.value)}}
          ])}
          <div id='button-panel'>
          <Button type="button" id="newBS" onClick={generateBackstory} className="BsButton1">
            Generate new BackStory
          </Button>
          <ContinueButton message={savedMessageVisible && (
            <div className="updated">Backstory saved successfully!</div>
          )} onClick={saveBackstory}/>
          </div>
        </Form>
        
        </Wrapper>
      </Page>
  );
};

export default BackstoryPage;