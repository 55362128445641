import { Page, Wrapper } from '@files'

import '@css/pages/LandingPage.scss'
import logo from '@rasters/logo.png'
import { isAuthenticated } from '@utilities/authUtils';
import { useNavigate } from 'react-router-dom';

const image="https://placehold.co/450x250";

function LandingPage() {
    const navigate = useNavigate();
    if(isAuthenticated) {
        navigate('/home')
    }
    return(
        <Page id="landing">
            <svg className="hidden-svg">
                <clipPath id="banner_bottom" clipPathUnits="objectBoundingBox"><path d="M0,1 V0 H1 V1 C0.65,-0.25,0.35,-0.25,0,1"></path></clipPath>
                <clipPath id="features_image_clip" clipPathUnits="objectBoundingBox"><path d="M0,1 H1 C0.65,-0.333,0.35,-0.333,0,1"></path></clipPath>
            </svg>
            <Wrapper>
                <div id="main">
                    <section id="banner">
                        <div id="BannerGradient">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="xMidYMin slice"><path d="M0,192L240,96L480,192L720,224L960,32L1200,256L1440,128L1440,320L1200,320L960,320L720,320L480,320L240,320L0,320Z"></path></svg>
                        </div>
                        <div id="BannerStrip">
                            <h1>Hephaestus</h1>
                            <h2>DND Character Generator Tool</h2>
                            <img src={logo} alt="Hammer logo"/>
                        </div>
                        <div id="BannerBottom"></div>
                        <div id="BannerButtons">
                            <a href="/signup" id="register">Register</a>
                            <a href="/login" id="login">Login</a>
                        </div>
                    </section>
                    <section id="about">
                    <p><strong>Hephaestus</strong> is a free online tool for D&D 5e that lets you create characters and build printable character sheets. It is designed to alleviate the tedious side of copying ability descriptions, skills, and the calculation-heavy determination of stats and proficiencies in D&D.</p>
                    <p>Several aspects of D&D 5e character creation are automated. With a simple and clean UI to walk you through the process, building your next character is a quick and painless process.</p>
                    <p><i className="dice fa-regular fa-dice"></i></p>
                    </section>
                </div>
                <section id="features">
                    <div id="PreviewsHeader">
                        <div className="tuckedstrip"></div>
                        <div className="clippedimage"></div>
                        <div className="shadowgrad"></div>
                    </div>
                    <div id="Previews">
                        <title>Features</title>
                        <div className="featuresbox">
                            <div className="featimgbox"><img className="featuredimage" alt="lorem ipsum" src={image}/></div>
                            <div className="separator"></div>
                            <div className="description">
                                <h1>feature one</h1>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti ullam error veniam quae. Cupiditate adipisci laboriosam necessitatibus consectetur officiis doloremque facilis! Id maiores officiis dolorem delectus mollitia porro! Eaque, quas.
                            </div>
                        </div>
                        <div className="featuresbox">
                            <div className="featimgbox"><img className="featuredimage" alt="lorem ipsum" src={image}/></div>
                            <div className="separator"></div>
                            <div className="description">
                                <h1>feature two</h1>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti ullam error veniam quae. Cupiditate adipisci laboriosam necessitatibus consectetur officiis doloremque facilis! Id maiores officiis dolorem delectus mollitia porro! Eaque, quas.
                            </div>
                        </div>
                    </div>
                </section>
            </Wrapper>
        </Page>
    );
}

export default LandingPage