import { Button } from "@files"
import '@css/shared/ContinueButton.scss'

function ContinueButton({children, message}){
    return (
        <div id="continue-grp">
            <Button id="continue-btn" type="submit">
            {children ?? <>continue <i className="fa-light fa-arrow-right"></i></>}
            </Button>
            {message}
        </div>
    )
}
export default ContinueButton;