import React, { useState, useEffect } from 'react';
// import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Wrapper, Details, Page, Header, CharacterNavbar, APICall, ContinueButton, Loader, Form, ServerCall, SelectionInputs } from '@files'
import '@css/pages/InventoryPage.scss'
import { useNavigate } from 'react-router-dom';

// items will be stored as:
// struct{string name, int quantity}

function InventoryPage(){
    const [classInventory, setClassInventory] = useState([]);
    const [backgroundInventory, setBackgroundInventory] = useState([]);
    const [classItemChoices, setClassItemChoices] = useState([])
    const [backgroundItemChoices, setBackgroundItemChoices] = useState([]);

    const [loadingClassItems, setLoadingClassItems] = useState(true);
    const [loadingBackgroundItems, setLoadingBackgroundItems] = useState(true);

    const [message, setMessage] = useState([]);

    const navigate = useNavigate();


    useEffect( () =>{
        async function getClassItemData(){
            try {
                // gets url for API from session storage
                // thing return should be a json containing all class information
                const response = await APICall("/api/classes/" + sessionStorage.getItem("class").toLowerCase());
                const startingEquipment = response['starting_equipment'].map(e=>({
                    name: e.equipment.name,
                    quantity: e.quantity
                }));
                setClassInventory(startingEquipment)            

                const classChoiceData = await ServerCall({url: '/equip-options?class=' + response["name"], method: 'get'});
                const equipmentOptions = classChoiceData.choices.map(e=>({
                    prompt: e.prompt,
                    limit: e.pick,
                    options: e.options
                }))
                setLoadingClassItems(false);
                setClassItemChoices(equipmentOptions);
            } catch (error) {
                setLoadingClassItems(false);
            }
        }

        async function getBackgroundItemData(){
            try {
                const response = await ServerCall({url: '/backgrounds?name=' + sessionStorage.getItem("background"), method: 'get'});
                
                setBackgroundInventory(response.equipment)

                const backgroundOptions = response['equipment_choices']?.map(e=>({
                    prompt: e.prompt,
                    options: e.options
                }))
                setBackgroundItemChoices(backgroundOptions);
                setLoadingBackgroundItems(false);
            } catch(error) {
                setLoadingBackgroundItems(false);
            }
        }

        ['class', 'background'].forEach((e) => {
            if(sessionStorage.getItem(e)===null) {
                navigate('/character')
            }
        })

        getClassItemData();
        getBackgroundItemData();
    },[]);

    function handleSubmit(e) {
        e.preventDefault();
        
        const form = e.target;
        const formData = new FormData(form);

        const inventory = {
            class: {
                starting: classInventory.map(e=> e.name + " x" + e.quantity),
                chosen: []
            },
            background: {
                starting: backgroundInventory,
                chosen: []
            }
        };
        for(const [k,v] of formData.entries()) {
            const key = k.slice(0,-1);
            inventory[key].chosen.push(v);
        }

        console.log(Array.from(formData.keys()).length, classItemChoices?.length + backgroundItemChoices?.length)

        if(Array.from(formData.keys()).length < ((classItemChoices?.length ?? 0) + (backgroundItemChoices?.length ?? 0))) {
            setMessage(<div className="error">Please select all options before continuing</div>)
            return false;
        }

        for(const [i, v] of Object.entries(classItemChoices)) {
            if((formData.getAll("class"+i)?.length ?? 0) > v.limit) {
                setMessage(<div className="error">Too many items selected</div>)
                return false;
            }
        }
        // put data in session storage as an array of strings
        sessionStorage.setItem("inventory", JSON.stringify(inventory));
        navigate('/backstory')
    }

    if(loadingBackgroundItems || loadingClassItems) return <Loader/>

    const inventorydefaults = JSON.parse(sessionStorage.getItem('inventory')) ?? null

    return (
        <Page id="inventory">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                <Form id="choose-inventory" onSubmit={handleSubmit}>
                    <div id="inventory-container">
                        <div className="inv-box">
                            <h1>Starting Class Equipment</h1>
                            {classInventory.map(e => <div className="entry">{e.name} x{e.quantity}</div> )}
                        </div>
                        <div className="inv-box">
                            <h1>Starting Background Equipment</h1>
                            {backgroundInventory.map(e => <div className="entry">{e}</div> )}
                        </div>
                        {classItemChoices.map((i, index)=>
                            <Details summary={i.prompt.replace(':','').trim()}>
                                <SelectionInputs
                                    type={i.limit===1 ? "radio" : "checkbox"}
                                    name={"class"+index}
                                    max={i.limit>1 && i.limit}
                                    options={i.options.map(o=> ({
                                        id: "class"+index+"-"+o.toLowerCase(),
                                        label: o,
                                        value: o,
                                        defaultChecked: inventorydefaults ? inventorydefaults.class.chosen.indexOf(o)>-1 : false
                                }))}/>
                            </Details>
                        )}
                        {backgroundItemChoices?.map((i, index)=>
                            <Details summary={i.prompt.replace(':','').trim()}>
                                <SelectionInputs
                                    type="radio"
                                    name={"background"+index}
                                    options={i.options.map(o=> ({
                                        id: "background"+index+"-"+o.toLowerCase(),
                                        label: o,
                                        value: o,
                                        defaultChecked: inventorydefaults ? inventorydefaults.background.chosen.indexOf(o)>-1 : false
                                }))}/>
                            </Details>
                        )}
                    </div>
                    <ContinueButton message={message}/>
                </Form>
            </Wrapper>
        </Page>
    )
}

export default InventoryPage;