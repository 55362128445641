import { useRef, useState, useEffect } from 'react';
import '@css/components/Details.scss'

function Details({children, summary, open}) {
    const showRef = useRef({})
    const [style, setStyle] = useState({
        height: "0px"
    })

    useEffect(() => {
        setStyle({
            height: (open ? showRef.current['body'].offsetHeight : 0) + "px",
            transitionDuration: (open ? showRef.current['body'].offsetHeight/1000 : .45) + "s"
        });
      }, []);

    return(
        <div className="details">
            <div className="summary" onClick={()=>{
                const cont = showRef.current['container'], body = showRef.current['body'], show = !cont.classList.contains('show'), body_height = body.offsetHeight;
                cont.classList.toggle('show');
                setStyle({
                    height: (show ? body_height : 0) + "px",
                    transitionDuration: (show ? body_height/1000 : .45) + "s"
                })
            }}>{summary}</div>
            <div className={"details-container" + (open ? " show" : '')} ref={el=>showRef.current['container']=el} style={{...style}}>
                <div className="body" ref={el=>showRef.current['body']=el}>
                    {children}
                </div>
            </div>
        </div>
    )
}


export default Details;