import { ContinueButton, Form, Page, Wrapper } from '@files';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import '@css/pages/UserAccess.scss';

function LoginPage() {
    const navigate = useNavigate(); // Initialize the navigate function
    // Error message hook
    const  [errorMessage, setErrorMessage] = useState('');
    // Makes a fetch request to the /login endpoint on form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const JsonData = Object.fromEntries(formData.entries());
        // Send `loginData` to localhost:8080 (our back-end)
        // use the route "/login" so the server's router hands the request to the `loginController`
        fetch('https://hephaestus.digital/api/login', {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify(JsonData),
        })
        .then(async response => {
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const data = await response.json(); // Parse JSON response
                if(response.ok) {
                    // Saver JWT to localStorage or in-memory storage
                    localStorage.setItem('token', data.token);
                    setErrorMessage('Logged In! Redirecting...');
                    // Redirect to protected route or home page
                    navigate('/home')
                } else {
                    setErrorMessage(data.error || 'Invalid email or password.')
                }
            } else {
                console.log("Hit else in content type:")
                const text = await response.text(); // Fallback to the  text response
                throw new Error(text || 'Server did not return a valid response');
            }
        })
        .catch(error =>{
            console.error('Error during login: ', error);
            setErrorMessage(error.message || 'Network Error. Please try again.');
        });
    };

    return (
        <Page id="access">
            <Wrapper>
                <div id="login">
                    <h1>Log In</h1>
                    
                    <Form onSubmit={handleSubmit}>
                        <div className="inner-form">
                            <label htmlFor="email">Email address</label>
                            <input id="email" type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    required
                            />

                            <label htmlFor="password">Password</label>
                            <input id="password" type="password"
                                    placeholder="Password"
                                    name="password"
                                    required
                            />
                        </div>
                        <ContinueButton message={errorMessage}>Log in</ContinueButton>
                    </Form>

                </div>
            </Wrapper>
        </Page>
    )
};

export default LoginPage;