import React, { useState} from 'react';

import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { CharacterNavbar, Button, Page, Wrapper, Header, ContinueButton, Form } from '@files'

import '@css/pages/CharImagePage.scss'

function CharImagePage() {
  //const navigate = useNavigate();

  const [imageURL, setImageURL] = useState( sessionStorage.getItem('character image') ?? "https://i.ibb.co/k4T6qzQ/a2.png");
  // For displaying a 'story saved' message
  const [savedMessageVisible, setSavedMessageVisible] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  ['character name', 'class', 'race', 'background'].forEach((e) => {
    if(sessionStorage.getItem(e)===null) {
        navigate('/character')
    }
  })

  const characterData = {
    charName: sessionStorage.getItem('character name') ?? '',
    charRace: sessionStorage.getItem('race') ?? '',
    charLevel: sessionStorage.getItem('level') ?? 1,
    charClass: sessionStorage.getItem('class') ?? '',
    background: sessionStorage.getItem('background') ?? ''
  };

  const generateImage = async () => {
    // Prepare character data to send
    console.log("TRY TO POST: ", JSON.stringify(characterData))

    try {
      // make a POST request to the backend
      const response = await fetch('https://hephaestus.digital/api/charimage/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        body: JSON.stringify(characterData),
      });

      // Parse response as JSON
      const data = await response.json();
      console.log("DATA RECEIVED: ", JSON.stringify(data))

      if (data.status === "error") {
        // display an error if unsuccessful
        setErrorMessage(<div className="error">{data.message}</div>);
      } else {
        // Update the state with the image
        setErrorMessage(null);
        setImageURL(data.imageurl);
      }
      
    } catch (error) {console.error('Error: ', error);}
  };

  const saveImageURL = () => {
    // save to session storage
    sessionStorage.setItem('character image', imageURL);
    setSavedMessageVisible(true);
    // Hide the message after 3 seconds
    setTimeout(() => {
      setSavedMessageVisible(false);
    }, 3000);
    navigate('/view')
  };

  return (

    <Page id="charimage">
      <Header><CharacterNavbar/></Header>
      <Wrapper>
        <a id='ReqAttrs' href='/character'>
          <Button><b>Name:</b> {characterData.charName}</Button>
          <Button><b>Class:</b> {characterData.charClass}</Button>
          <Button><b>Level:</b> {characterData.charLevel}</Button>
          <Button><b>Race:</b> {characterData.charRace}</Button>
          <Button><b>Background:</b> {characterData.background}</Button>
        </a>

        <Form onSubmit={saveImageURL}>
          <label id="avatar_result" htmlFor="avatar">
            <img src={imageURL}/>
          </label>
          <input required={true} id="avatar" value={imageURL} type="url" onChange={(e)=>setImageURL(e.target.value)}/>

          <div id='button-panel'>
          <Button type="button" id="newBS" onClick={generateImage} className="BsButton1">
            Generate new character image
          </Button>
          <ContinueButton message={
            <>
            {savedMessageVisible && (<div className="updated">Character image saved successfully!</div>)} 
            {errorMessage}
            </>
          }/>
          </div>
        </Form>
      </Wrapper>
    </Page>
  );
};

export default CharImagePage;