/* Description is integrated within the Forms.js.
name: think of this as the group name, like class selection or race selection. needed, in case there are multiple forms on the same page.
options: (an array with each entry as an object)
{
    id:
    description:
}
*/

export function ShowDescription(args, optionRef) {
    const { id } = args;
    for(const e in optionRef.current) {
        e === id ? optionRef.current[e].classList.add("active") : optionRef.current[e].classList.remove("active");
    }
}

function Description(args, options, optionRef) {
    const { name } = args;
    const result = [];
    for (const e of options) {
        if(!e.description) continue;
        result.push(
            <div className="description" id={e.id} ref={el => optionRef.current[e.id] = el}>
                {e.description}
            </div>
        )
    }
    if (result.length>0) return(
        <div className="descriptions" id={name}>
            <div className="description active" ref={el => optionRef.current[null] = el}>
                (Select an option to see description)
            </div>
            {result}
        </div>
    )
}

export default Description;