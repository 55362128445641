import { useRef, useState } from "react";
import Description, { ShowDescription } from "./InputDescription";
import '@css/components/InputsSelection.scss';

/*
type: checkbox, radio
name: think of this as the group name, like class selection or race selection.
max: [for checkboxes] maximum # to select

options: (an array with each entry as an object)
{
    id:
    label:
    description:
    checked: {by default: false, true} 
    defaultChecked: {by default: false, true} 
}
*/

function SelectionInputs(args, o) {
    const { type, name, max, onChange, required } = args;
    const options = args.options ?? o;
    const result = [];
    const optionRef = useRef({});
    const [checked, setChecked] = useState(Object.fromEntries(options.map(e => [e.id, null])))

    function handleChange(e, opt) {
        if(max && type==='checkbox') {
            const isSelected = e.target.checked;
            if(isSelected){
                if(Object.entries(checked).reduce((acc, curr)=> acc + (curr[1] ? 1 : 0), 0) < max){
                    const newvar = {...checked};
                    newvar[e.target.id] = true;
                    setChecked(newvar);
                }
            }
            else {
                const newvar = {...checked};
                newvar[e.target.id] = false;
                setChecked(newvar);
            } 
        }
        ShowDescription({id: opt.id}, optionRef)
        if(onChange) onChange(e)
    }

    function InputSelection(e) {
        const inputoptions = {
            required: required,
            type: type,
            id: e.id,
            name: name,
            onChange: ($this) => handleChange($this, e),
            value: e.value ?? e.id,
            className: e.className
        }
        const checkoption = (max && type==='checkbox') ? {checked: (max && type==='checkbox') ? checked[e.id] ?? e.checked ?? false : null} : e.defaultChecked ? {defaultChecked: e.defaultChecked} : null

        max && type==='checkbox' && (e.checked || e.defaultChecked) && checked[e.id]===null && (checked[e.id] = true)

        return(<>
            <label htmlFor={e.id} tabIndex={0}>{e.label}
                <input {...inputoptions} {...checkoption}/>
            </label>
        </>);
    }

    for(const e of options) {
        result.push(InputSelection(e));
    }
    
    return(
        <div className="selection-inputs" id={name}>
            <div className="inputs">{result}</div>
            {Description({name: name}, options, optionRef)}
        </div>
    )
}

export default SelectionInputs;