import '@css/pages/ViewPage.scss'
import { Page, Wrapper, Header, CharacterNavbar, Form, ServerCall, Details, SelectionInputs, APICall, Loader, ContinueButton } from '@files';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AutoTextSize } from 'auto-text-size';
import Markdown from 'react-markdown'

function ViewPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState({
        "getSpells": true
    })
    const [message, setMessage] = useState(null)
  
    const session = {
        'character name': null, 
        'class': null, 
        'race': null, 
        'spells': null,
        'level': null,
        'background': null, 
        'ability scores': null, 
        'skill proficiencies': null, 
        'inventory': null, 
        'backstory': null,
        'character image': null
    };

    const parseJson = (k) => {
        try {
            return JSON.parse(sessionStorage.getItem(k))
        } catch(err) {
            return sessionStorage.getItem(k)
        }
    }
    for (const k in session) session[k] = parseJson(k)
    const [spells, setSpells] = useState(null)
    const [inventory, setInventory] = useState(null)

    useEffect(()=>{
        async function getSpells() {
            const spells_list = []
            for(const [lvl,val] of Object.entries(session['spells'])) {
                for(const spell of val) {
                    try {
                        const response = await APICall('/api/spells/' + spell.toLowerCase().replaceAll(' ','-'))
                        const newspells = {
                            name: spell,
                            index: spell.toLowerCase().replaceAll(' ','-'),
                            level: lvl,
                            description: response.desc
                        }
                        spells_list.push(newspells)
                    } catch(error) {
                        console.log(error)
                    }
                }
            }
            setSpells(spells_list)
            setLoading({...loading, "getSpells": false})
        }
        if(Object.values(session).reduce((acc,curr)=> (acc + (curr===null) ? 1 : 0), 0)===0) {
            getSpells()
        }
    },[])

    if(Object.values(session).reduce((acc,curr)=> (acc + (curr===null) ? 1 : 0), 0) > 0) return(
        <Page id="view">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                Missing: 
                { Object.entries(session).map( ([k, v]) => 
                    (v===null) && ((k==='level') || k) 
                ) }
            </Wrapper>
        </Page>
    )

    if(Object.values(loading).reduce((acc,curr)=> (acc + (curr===true) ? 1 : 0), 0) > 0) return <Loader/>    

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const sender = await ServerCall({url: '/character', method: 'Post', body: {additional_properties: session}, headers: {"CharacterID": sessionStorage.getItem('character id') ?? 0}})

            const mainproperties = JSON.parse(sender['additional_properties'])
            console.log("id" + mainproperties)
            sessionStorage.setItem('character id', sender['character_id']);
            // for(const [k,v] of Object.entries(mainproperties)) {
            //     sessionStorage.setItem(k, JSON.stringify(v))
            // }
            setMessage(<div className="updated">Saved successfully!</div>)
        } catch(error) {
            setMessage(<div className="error">Error in saving</div>)
        }
    }

    return(
        <Page id="view">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                <Form id="form-view" onSubmit={handleSubmit}>
                    
                    <div id="basic-info">
                        <h1>
                            <i className="ico fa-thin fa-stars"></i>
                            <AutoTextSize id="character-name" minFontSizePx={'24'} mode={'multiline'}>{session['character name']}</AutoTextSize>
                        </h1>
                        <div className="sub-bar">
                            <ContinueButton>Save Character</ContinueButton>
                            {message}
                            <span>level {session.level}</span>
                            <span>{session.class}</span>
                            <span>{session.race}</span>
                            <span>{session.background}</span>
                        </div>
                    </div>

                    <div id="scores">
                        {['str','dex','con','int','wis','cha'].map(stat=>
                            <div className="stat">
                                <div className="value">{session['ability scores'][stat]}</div>
                                <div className="label">{stat}</div>
                            </div>
                        )}
                    </div>
                    <div id="proficiencies-list">
                        {['Athletics'].map(e=>
                            <div className="prof">
                                <div className="label">{e}</div>
                                <div className="value">{Math.trunc(session['ability scores'].str/2) - 5 + (session['skill proficiencies'].indexOf(e)>-1 ? 2 : 0)}</div>
                            </div>
                        )}
                        {['Acrobatics','Sleight of Hand','Stealth'].map(e=>
                            <div className="prof">
                                <div className="label">{e}</div>
                                <div className="value">{Math.trunc(session['ability scores'].dex/2) - 5 + (session['skill proficiencies'].indexOf(e)>-1 ? 2 : 0)}</div>
                            </div>
                        )}
                        {['Arcana','History','Investigation','Nature','Religion'].map(e=>
                            <div className="prof">
                                <div className="label">{e}</div>
                                <div className="value">{Math.trunc(session['ability scores'].int/2) - 5 + (session['skill proficiencies'].indexOf(e)>-1 ? 2 : 0)}</div>
                            </div>
                        )}
                        {['Animal Handling', 'Insight', 'Medicine', 'Perception', 'Survival'].map(e=>
                            <div className="prof">
                                <div className="label">{e}</div>
                                <div className="value">{Math.trunc(session['ability scores'].wis/2) - 5 + (session['skill proficiencies'].indexOf(e)>-1 ? 2 : 0)}</div>
                            </div>
                        )}
                        {['Deception', 'Intimidation', 'Performance', 'Persuasion'].map(e=>
                            <div className="prof">
                                <div className="label">{e}</div>
                                <div className="value">{Math.trunc(session['ability scores'].cha/2) - 5 + (session['skill proficiencies'].indexOf(e)>-1 ? 2 : 0)}</div>
                            </div>
                        )}
                    </div>

                    <div id="details-boxes">

                        {session['character image'] && 
                        <Details summary="Image">
                            <img id="detailimage" src={session['character image']}/>
                        </Details>
                        }

                        {spells.length>0 && <Details summary="Spell List">
                            <SelectionInputs type="radio" name="spells" options={spells.map(e=>({
                                id: e.index, label: e.name, description: e.description.map(e=><p><Markdown>{e}</Markdown></p>), value: e.name, className: "spells"+e.level
                            }))} />
                            
                        </Details>}

                        <Details summary="Inventory List">
                            {Object.values(session['inventory']).map( (o) => Object.values(o).map(e=>
                                e.map(inv => 
                                    <div className="inv">{inv}</div>
                                )
                            ) )}
                        </Details>

                        <Details summary="Backstory">
                            <div id="backstory-inner">
                                {session['backstory']}
                            </div>
                        </Details>
                    </div>
                </Form>
            </Wrapper>
        </Page>
    )
}

export default ViewPage