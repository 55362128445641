async function Fetch({url, method, body, headers}) {
    try{
        const response = await fetch(url, {
            method: method,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
                ...headers
            },
            body: JSON.stringify(body),
            redirect: "follow"
        })
        if(!response.ok) throw new Error(await response.text());
        const json = await response.json();
        console.log({url, method, body, json})
        return json;
    } catch(error) {
        console.log({url, method, body, error});
        throw new Error(error.message);
    }
}

export async function ServerCall({url, method, body, headers}) {
    try{
        const json = await Fetch({url: 'https://hephaestus.digital/api/' + url, method: method, body: body})
        return await json;
    } catch(error) {
        throw new Error(error.message);
    }
}

export async function GetUserInfo() {
    try{
        const json = await Fetch({url: 'https://hephaestus.digital/api/user', method: 'get'})
        return await json;
    } catch(error) {
        throw new Error(error.message);
    }
}

export async function APICall(url){
    try{
        const json = await Fetch({url: '//www.dnd5eapi.co'+ url, method: 'get'})
        return await json;
    } catch(error) {
        throw new Error(error.message);
    }
}

export default Fetch;