import {Navigate, Outlet } from 'react-router-dom';

// Helper function to get the token from local storage
// const getToken = () => {
//   console.log("getting token....");
//     return localStorage.getItem('token');
//   };

// Helper function to check if the user is authenticated
const isAuthenticated = () => {
    // Logic to check if the user is authenticated
      return !!localStorage.getItem('token');
};
  
const PrivateRoutes = () => {
return (
    isAuthenticated() ? <Outlet/> : <Navigate to='/login'/>
    )
};

// Custom hook to add Authorization header to fetch requests
const useAuthHeader = () => {
    const getToken = () => localStorage.getItem('token');
    const token = getToken();

    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

  export {isAuthenticated, useAuthHeader, PrivateRoutes};