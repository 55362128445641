import { useContext } from 'react';
import { Theme } from '@files';

import '@css/shared/ScaleControl.scss'

function ScaleControl() {
  const theme = useContext(Theme);
  const [,setScale] = theme._scale;

  function handleText(inc) {
    setScale((prev) => 2*inc + parseInt(prev));
  }

  return (
    <div id="scale-control">
      <i onClick={()=>handleText(-1)} className="fa-regular fa-minus"></i>
      <i className="fa-light fa-text-size"></i>
      <i onClick={()=>handleText(1)} className="fa-regular fa-plus"></i>
    </div>
  );
}

export default ScaleControl;