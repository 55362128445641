import React from 'react'
import { useState, useEffect } from 'react';

import {
  Theme,
  AppRouter,
  GetUserInfo,
  Loader, 
  Modal
} from '@files'


function App(){
  const [style, setStyle] = useState(null);

  const theme = localStorage.getItem('theme-mode') ?? 'light';
  const size = localStorage.getItem('text-scale') ?? 16;

  const [mode, setMode] = useState(theme);
  const [scale, setScale] = useState(size);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  localStorage.setItem('theme-mode', mode);
  localStorage.setItem('text-scale', scale);

  useEffect(() =>{
    async function fetchStyle() {
      try {
        const json = await GetUserInfo();
        setStyle(JSON.parse(json.info.style) ?? {});
        setLoading(false)
      } catch (error) {
        setError("Error: "+error.message)
        setLoading(false)
      }
    };
    fetchStyle();
  }, []);

  // if(error) return (<Modal><div className="error">{error}</div></Modal>)
  if(loading===true) return <Loader/>

  return (
      <Theme.Provider
        value={{
            _style: [style, setStyle],
            _mode: [mode, setMode],
            _scale: [scale, setScale]
        }}>
        <div style={{...style, '--text-size': scale}} theme-mode={theme} className="App">
          <AppRouter/>
        </div>
      </Theme.Provider>
  );
}


      {/* <Page idName="page1">
        <Navbar><a href="x">Hello!</a><a href="x">Hi!</a></Navbar>
        <Wrapper>
          <Form idName="form1" submitvalue="xyz">
            {SelectionInputs({type: "radio", name: "class"}, [
              {id: "barbarian", label: "Barbarian", description: "barbarian description"},
              {id: "fighter", label: "Fighter", description: "fighter description"}
            ])}
            {FieldInputs({name:"backstory"}, [
              {type:"textarea", id:"backstory", label: "Backstory", description: "insert dnd explanation of a background", placeholder:"put a backstory here :D"}
            ])}
          </Form>
        </Wrapper>
      </Page>
      {/*testing for ability score page DELETE FOR DEMO*/}
      {/* <a href="/ability">Ability Score Page</a>
      <DownloadPDF/>  */}

export default App;