import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Details, APICall, Loader, Page, Header, CharacterNavbar, Wrapper, SelectionInputs, Form, ContinueButton } from '@files'

import Markdown from 'react-markdown'

import '@css/pages/SpellcastingPage.scss'

function SpellcastingPage() {

    /*
        Notes on spells available to lvl. 1 classes
        Artificer   2 cantrips, 2 lvl 1     int
        Bard        2 cantrips, 4 lvl 1     cha
        Cleric      3 cantrips, 2 lvl 1     cha
        Druid       2 cantrips, wis+1 lvl 1 wis
        Sorcerer    4 cantrips, 2 lvl 1     cha
        Warlock     2 cantrips, 2 lvl 1     cha
        Wizard      3 cantrips, 6 lvl 1     int
    */

    //set up variables to limit checkboxes (should they be hooks?)

    //initialization of list and desc temporary, replace w/ api calls while setting up
    const [spellList, setSpellList] = useState(null)
    const [message, setMessage] = useState(null)
    const navigate = useNavigate();

    ['class', 'ability scores'].forEach((e) => {
        if(sessionStorage.getItem(e)===null) {
            window.location.pathname = '/ability'
        }
    })

    let maxSpells = [0, 0];
    const className = sessionStorage.getItem('class');
    const abilityData = JSON.parse(sessionStorage.getItem('ability scores')) ?? { str: 10, dex: 10, con: 10, int: 10, wis: 10, cha: 10 };
    //determine starting spell counts
    switch (className.toLowerCase()) {
        case "artificer":
            maxSpells = [2, 2]
            break;
        case "bard":
            maxSpells = [2, 4]
            break;
        case "cleric":
            maxSpells = [3, 2]
            break;
        case "druid":
            if(abilityData){
                let wisBonus = Math.floor(((abilityData?.wis??10) - 10)/2)
                if(wisBonus < 0){ wisBonus = 0 }
                maxSpells = [2, 1 + wisBonus]
            } else {
                maxSpells = [2,1] //failsafe; there should be ability data at this step
            }
            break;
        case "sorcerer":
            maxSpells = [4, 2]
            break;
        case "warlock":
            maxSpells = [2, 2]
            break;
        case "wizard":
            maxSpells = [3, 6]
            break;
        default:
            //invalid class; no spells
            maxSpells = [0, 0]
            break;
    }

    useEffect(() => {

        if(maxSpells[0]===0 || maxSpells[1]===0) {
            // navigate to next page
            sessionStorage.setItem('spells', JSON.stringify({}));
            navigate('/inventory')
        }

        //call API to fill out spellList and spellDesc
        //writing function inside useEffect because thats what CharacterForm does
        async function getSpellList() {
            try {
                const spells = {};
                    async function fetchSpells(lvl) {
                        const data = await APICall("/api/classes/" + className.toLowerCase() + "/levels/" + lvl + "/spells");
                        spells[lvl] = [];
                        for(let i = 0; i < data.count; i++){
                            const desc = await APICall(data.results[i].url)
                            spells[lvl].push({
                                'name': data.results[i].name,
                                'index': data.results[i].index,
                                'url': data.results[i].url,
                                'description': desc.desc
                            })
                        }
                    }
                await fetchSpells(0)
                await fetchSpells(1)
                //update hook with all spells
                setSpellList(spells)
            } catch (error) {
                console.log(error)
                setSpellList(error)
            }
        }
        getSpellList();
    },[]);

    if(spellList===null) return <Loader/>

    function handleSubmit(e) {
        //save to session storage
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = {
            0: formData.getAll("0"),
            1: formData.getAll("1")
        }
        const errorMessage = [];
        for(const [k, v] of Object.entries(formJson)) {
            if(v.length > maxSpells[k]) errorMessage.push(k + (k==1 ? 'st' : k==2 ? 'nd' : k==3 ? 'rd' : 'th'))
        }
        if (errorMessage.length > 0) {
            setMessage(<div className="error">Too many {errorMessage.join(", ")} level spells selected</div>)
            return false;
        }
        for(const [, v] of Object.entries(formJson)) {
            if (v.length===0) {
                setMessage(<div className="error">Please select at least one spell from each list before continuing</div>)
                return false;
            }
        }
        sessionStorage.setItem('spells', JSON.stringify(formJson))
        setMessage(<div className="updated">Saved</div>)
        //redirect to next page (wherever that is)
        navigate('/inventory')
    }

    const loadedspells = JSON.parse(sessionStorage.getItem('spells')) ?? null
    return (
        <Page id="spellcasting">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                <Form onSubmit={handleSubmit}>
                    <Details id="select-cantrips" summary={"Select "+maxSpells[0]+" Cantrips"}>
                        <div className="inner-select">
                            <SelectionInputs type="checkbox" name="0" max={maxSpells[0]} options={spellList[0].map(e=>({
                                id: e.index, label: e.name, description: e.description.map(e=><p><Markdown>{e}</Markdown></p>), value: e.name, max: maxSpells[0], checked: loadedspells ? loadedspells['0']?.indexOf(e.name)>-1 : false
                            }))}/>
                        </div>
                    </Details>
                    <Details id="select-spells" summary={"Select "+maxSpells[1]+" Spells"}>
                        <div className="inner-select">
                            <SelectionInputs type="checkbox" name="1" max={maxSpells[1]} options={spellList[1].map(e=>({
                                id: e.index, label: e.name, description: e.description.map(e=><p><Markdown>{e}</Markdown></p>), value: e.name, checked: loadedspells ? loadedspells['1']?.indexOf(e.name)>-1 : false
                            }))}/>
                        </div>
                    </Details>
                    <ContinueButton message={message}/>
                </Form>
            </Wrapper>
        </Page>
    )
}

export default SpellcastingPage