import "@css/components/Navbar.scss";

import ModeControl from "@components/shared/ModeControl";
import ScaleControl from "@components/shared/ScaleControl";

function Navbar(args) {
    const { children } = args;

    return(
        <nav id="navigation-bar">
            {children}
            <div id="theme-controls">
                <ScaleControl/>
                <ModeControl/>
            </div>
        </nav>
    )
}

export default Navbar;