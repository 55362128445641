function Button(args){
    const { children } = args;
    const attr = {
        id: args.id,
        className: args.className,
        onClick: args.onClick,
        type: args.type
    }
    
    return(
        <button {...attr}>
            {children}
        </button>
    );
}

export default Button;