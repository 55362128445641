import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BackstoryPage, CharImagePage, LandingPage, SignupPage, AbilityPage, CharacterPage, LoginPage, HomePage, PreferencesPage, SpellcastingPage, InventoryPage, ViewPage, PrivateRoutes, Logout } from '@files'

function AppRouter() {

    return (
        <Router>
            <Routes>
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<Logout/>} />
                <Route index path='/' element={<LandingPage />} />
                {/* Protected Route */}
                <Route element={<PrivateRoutes/>}>
                  <Route path='/home' element={<HomePage/>} />
                  <Route path="/settings" element={<PreferencesPage/>} />
                  
                  <Route path="/character" element={<CharacterPage/>} />
                  <Route path='/ability' element={<AbilityPage/>} />
                  <Route path="/spellcasting" element={<SpellcastingPage/>}/>
                  <Route path="/inventory" element={<InventoryPage/>} />
                  <Route path='/backstory' element={<BackstoryPage/>} />
                  <Route path='/charimage' element={<CharImagePage/>} />
                  <Route path="/view" element={<ViewPage/>}/>
                </Route>
                
                <Route path="*" element={<>404</>}/>
            </Routes>
        </Router>
    )
}

export default AppRouter;