import { Modal } from "@files";
import '@css/shared/Loader.scss';

function Loader({children}) {
    return(
        <Modal id="loading">
            <div className="loader"></div>
        </Modal>
    )
} 

export default Loader;