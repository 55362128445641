import React, { useState } from 'react';
import { ContinueButton, Form, Page, Wrapper } from '@files';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import '@css/pages/UserAccess.scss';

function SignupPage() {

    const navigate = useNavigate(); // Initialize the navigate function
    // Error message hook
    const [errorMessage, setErrorMessage] = useState('');

    // Makes a fetch request to the /signup endpoint on form submission.
    const handleSubmit = (e) => { //capture event
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const JsonData = Object.fromEntries(formData.entries());
        // Send `formData` to localhost:8080 (our back-end)
        // use the route "/signup" so the server's router hands the request to the `signupController`
        fetch('https://hephaestus.digital/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(JsonData),
        })
        .then(async response => {
            // Check if the response is JSON
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const data = await response.json(); // Parse JSON response
                if(response.ok) {
                    console.log(data);
                    setErrorMessage('');
                    // Force the user to login
                    navigate('/home')
                } else {
                    setErrorMessage(data.error || 'An error occurred. Please try again.');
                }
            } else {
                const text = await response.text(); // Fallback to text response
                throw new Error(text || 'Server did not return a valid response.');
            }
        })
        .catch(error => {
            console.error('Error during signup:', error);
            setErrorMessage(error.message || 'Network error. Please try again.');
        });
    };

    return (
        <Page id="access">
            <Wrapper>
                <div id="signup">
                    <h1>Sign Up</h1>
                    
                    <Form onSubmit={handleSubmit}>
                        <div className="inner-form">
                            <label htmlFor="username">Username</label>
                            <input id="username" type="username"
                                    placeholder="Enter username"
                                    name="username"
                                    required
                            />

                            <label htmlFor="email">Email address</label>
                            <input id="email" type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    required
                            />

                            <label htmlFor="password">Password</label>
                            <input id="password" type="password"
                                    placeholder="Password"
                                    name="password"
                                    required
                            />
                        </div>
                        <ContinueButton message={errorMessage}>Sign up</ContinueButton>
                    </Form>

                </div>
            </Wrapper>
        </Page>
    )
};

export default SignupPage;