import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AutoTextSize } from 'auto-text-size';

// import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import { APICall, GetUserInfo, Page, Header, Wrapper, HomeNavbar, Loader, ServerCall, Modal } from '@files'

import "@css/pages/HomePage.scss";

function HomePage() {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [userInfo, setUserInfo] = useState(null); // State to hold user info
  const [options, setOptions] = useState(null); // hold class/race sorting options
  const [search, setSearch] = useState({
    name: '',
    class: '',
    race: ''
  });
  const [sort, setSort] = useState({
    sortby: 'name',
    order: "1"
  })
  const showRef = useRef({})
  const [modal, setModal] = useState(null)

  useEffect(() =>{
    async function fetchUserInfo() {
      try {
        const json = await GetUserInfo();
        setUserInfo(json);
      } catch (error) {
        setUserInfo(error);
      }
    };
    async function getOptions(){
      try {
        const classes = await APICall('/api/classes');
        const races = await APICall('/api/races');
        setOptions({ classes: classes.results, races: races.results})
      } catch(error) {
        setOptions(error)
      }
    }
    fetchUserInfo();
    getOptions();
  }, []);

  if(userInfo===null||options===null) return <Loader/>

  function handleSearch(e) {
    const newval = {...search};
    newval[e.target.name] = e.target.value;
    setSearch(newval);
  }
  function handleSort(e) {
    const newval = {...sort};
    newval[e.target.name] = e.target.value;
    setSort(newval);
  }

  function CharacterList() {
    const character_list_data = userInfo.characters?.map((char)=>({
      name: char.properties['additional_properties']['character name'],
      class: char.properties['additional_properties']['class'],
      race: char.properties['additional_properties']['race'],
      image: char.properties['additional_properties']['character image'],
      id: char.id
    }));
    const sortFn = (a, b) => {
      return parseInt(sort.order) * a[sort.sortby].localeCompare(b[sort.sortby]);
    }
    const searchFn = (e) => {
      for(const prop in search) {
        const query = search[prop];
        if(!query || (prop=="name" && e["name"].trim().toLowerCase().includes(query.trim().toLowerCase())) ) {
          continue;
        } else if(query.toLowerCase()!=e[prop].toLowerCase()) {
          return false;
        }
      }
      return true;
    }
    function handleView(e) {
      const id = parseInt(e.target.id.split('-')[1]);
      let index = -1;
      for(let i=0, c=userInfo.characters; i < c.length;i++) {
        if(c[i]['id'] !== parseInt(id)) continue;
        index = i;
      }
      if(index === -1) return false;
      const chardata = userInfo.characters[index].properties['additional_properties'];
      sessionStorage.setItem('character id', id);
      
      for(const [k,v] of Object.entries(chardata)) {
        const vstringify = typeof v==='object' || Array.isArray(v);
          sessionStorage.setItem(k, vstringify ? JSON.stringify(v) : v)
      }
      navigate('/view')
    }
    return(
      character_list_data && character_list_data.filter(searchFn).sort(sortFn).map(e => 
        <Card name={e.name} classType={e.class} image={e.image} id={"char-"+e.id} onClick={handleView}/>
      )
    )
  }

  function Card({name, classType, onClick, image, id}) {
    function handleDelete(e, id, name) {
        setModal(
        <Modal className="delete-msg">
            <div className="details">Are you sure you want to delete <b>{name}</b>?</div>
            <div className="options">
                <button className="btn-yes" onClick={()=>onDelete(id)}>Yes</button>
                <button className="btn-no" onClick={()=>setModal(null)}>No</button>
            </div>
        </Modal>
        )
    }
    function onDelete(id) {
      try {
        const response = ServerCall({url: '/character', method: 'delete', headers: {'CharacterID': parseInt(id.split('-')[1])}})
        window.location.reload();
      } catch(error) {
        console.log(error)
      }
    }
    return(
      <div className="card">
        <div className="background">
          <img src={image} alt={name}/>
          <div className="char-grad-overlay"></div>
        </div>
        <div className="info">
          <span className="character-name">
            <AutoTextSize minFontSizePx={'24'} mode={'multiline'}>{name}</AutoTextSize>
          </span>
          <div className="character-status">
            <div className="level"><AutoTextSize mode={'oneline'} minFontSizePx={'2'}>lvl1</AutoTextSize></div>
            <div className="character-class">
              <AutoTextSize mode={'oneline'} minFontSizePx={'2'}>{classType}</AutoTextSize>
            </div>
          </div>
        </div>
        <div className="options">
          <button id={"button"+id} onClick={onClick}>View</button>
          <button onClick={(e) => handleDelete(e, id, name)}>Delete</button>
        </div>
      </div>
    );
  }

  return (
    <Page id="home">
      <Header><HomeNavbar avatar={userInfo.info?.avatar ?? 'https://i.ibb.co/KGxyQ8b/14.png'}/></Header>
      <Wrapper>
        {modal}
        <div id="welcome">
          Welcome, {userInfo.info?.name ?? 'Adventurer'}!
        </div>

        <div id="latest-character">
          <div id="featured-character"><img src={"https://placehold.co/500x500"} alt="" /></div>
        </div>

        <div id="character-list">
          <form id="search" onSubmit={(e)=>{e.preventDefault()}}>
            <h1 id="searchbar-title" onClick={()=>{
              const cont = showRef.current['search-container'], body = showRef.current['search-body'], hide = cont.classList.contains('hide'), body_height = body.offsetHeight;
              cont.classList.toggle('hide');
              cont.style.height = (hide ? 0 : body_height) + "px";
            }}>
              <i className="search-ico fa-light fa-magnifying-glass"></i> search by...
            </h1>
            
            <div className="search-container" ref={el=>showRef.current['search-container']=el}>
              <div id="search-body" ref={el=>showRef.current['search-body']=el}>
                <div className="search-type">
                  <label htmlFor="search-name">
                    <h2>name</h2>
                    <input type="text" name="name" placeholder="Character name" onChange={handleSearch}/>
                  </label>
                </div>
                <div className="search-type">
                  <label htmlFor="search-class">
                    <h2>class</h2>
                    <select name="class" defaultValue="" onChange={handleSearch}>
                      <option value="">Any class</option>
                      {options.classes.map(e=><option value={e.index}>{e.name}</option>)}
                    </select>
                  </label>
                </div>
                <div className="search-type">
                  <label htmlFor="search-race">
                    <h2>class</h2>
                    <select name="race" defaultValue="" onChange={handleSearch}>
                      <option value="">Any race</option>
                      {options.races.map(e=><option value={e.index}>{e.name}</option>)}
                    </select>
                  </label>
                </div>
                <div className="sorting">
                  <label htmlFor="sortby">
                    <h2>sort by</h2>
                    <select name="sortby" defaultValue="name" onChange={handleSort}>
                      {/*"Created", "Updated"*/}
                      {["Name", "Class", "Race", ].map(e=><option value={e.toLowerCase()}>{e}</option>)}
                    </select>
                  </label>
                </div>
                <div className="sorting">
                  <label htmlFor="order">
                    <h2>sort order</h2>
                    <select name="order" defaultValue="1" onChange={handleSort}>
                      <option value="1">Ascending</option>
                      <option value="-1">Descending</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div id="characters">
            <CharacterList/>
          </div>
        </div>

      </Wrapper>
    </Page>
  );
}

export default HomePage;
