import { useNavigate } from 'react-router-dom';

import '@css/shared/NavigateBack.scss';

function NavigateBack() {
    const navigate = useNavigate();
    return(
        <div id="navigate-back" onClick={() => navigate('/home')}>
            <i className="fa-light fa-arrow-left"></i> back
        </div>
    )
}

export default NavigateBack;