import '@css/components/Wrapper.scss'

function Wrapper(args) {
    const { children } = args;

    return(
        <div className="wrapper">
            <div className="innerwrapper">
                {children}
            </div>
        </div>
    )
}

export default Wrapper;