import { Navbar, NavigateBack } from "@files";

function CharacterNavbar() {
    return (
        <Navbar>
            <NavigateBack/>
            <a className="sheet-links" href="/character">Character</a>
            <a className="sheet-links" href="/ability">Ability</a>
            <a className="sheet-links" href="/spellcasting">Spells</a>
            <a className="sheet-links" href="/inventory">Inventory</a>
            <a className="sheet-links" href="/backstory">Backstory</a>
            <a className="sheet-links" href="/charimage">Image</a>
            <a className="sheet-links" href="/view">View</a>
        </Navbar>
    );
}
export default CharacterNavbar