import React, { useState, useEffect } from 'react';
import { Details, APICall, SelectionInputs, Loader, Form, Page, Wrapper, Header, CharacterNavbar, Button, ContinueButton, ServerCall } from '@files';
import { useNavigate } from "react-router-dom";

import '@css/pages/CharacterPage.scss';

const CharacterPage = () => {
    
    const navigate = useNavigate();
    const [classOptions, setClassOptions] = useState(null);
    const [raceOptions, setRaceOptions] = useState(null);
    const [backgroundOptions, setBackgroundOptions] = useState(null);
    const [message, setMessage] = useState(null)

    useEffect(()=>{
        async function getAPIOptions() {
            try {
                const classes = await APICall('/api/classes');
                const races = await APICall('/api/races');
                const backgrounds = await ServerCall({url: '/backgrounds', method: 'get' });

                setClassOptions(classes.results);
                setRaceOptions(races.results);
                setBackgroundOptions(backgrounds)

              } catch(error) {
                setClassOptions(error);
                setRaceOptions(error);
                setBackgroundOptions(error);
              }
        }
        getAPIOptions();
        // getBackgroundOptions();
    },[])

    //on submit do nothing
    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        const errorMessage = ['name', 'class', 'race', 'background'];
        console.log(formJson)
        for(const key in formJson) errorMessage.splice(errorMessage.indexOf(key),1)
        if (errorMessage.length > 0) {
            setMessage(<div className="error">Please select before continuing: {errorMessage.join(", ")}</div>)
            return false;
        }
        sessionStorage.setItem("character name", formJson.name);
        sessionStorage.setItem("class", formJson.class);
        sessionStorage.setItem("race", formJson.race);
        sessionStorage.setItem("background", formJson.background);
        sessionStorage.setItem("level", 1);
        setMessage(<div className="updated">Saved</div>)
        // navigate to next page in form here
        navigate("/ability")
    }
    

    if(classOptions===null || raceOptions===null || backgroundOptions===null) return <Loader/>

    const defaults = {
        name: sessionStorage.getItem("character name"),
        class: sessionStorage.getItem("class"),
        race: sessionStorage.getItem("race"),
        background: sessionStorage.getItem("background")
    }

    return(
        <Page id="character">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                <Form onSubmit={handleSubmit} id="character-form">
                    <input id="name" type="text"
                            placeholder="Enter your character's name"
                            name="name"
                            defaultValue={defaults.name}
                            required/>
                    <Details summary="Choose a Class" open={true}>
                        <SelectionInputs
                            type="radio"
                            name="class"
                            options={classOptions.map(option=> (
                                {id: option.index, label: option.name, value: option.name, description: '', defaultChecked: defaults.class===option.name}
                            ))}/>
                    </Details>
                    <Details summary="Choose a Race">
                        <SelectionInputs
                            type="radio"
                            name="race"
                            options={raceOptions.map(option=> (
                                {id: option.index, label: option.name, value: option.name, description: '', defaultChecked: defaults.race===option.name}
                            ))}/>
                    </Details>
                    <Details summary="Choose a Background">
                        <SelectionInputs
                            type="radio"
                            name="background"
                            options={backgroundOptions.names.map(option=> ({id: option, label: option, value: option, description: '', defaultChecked: defaults.background===option}))}/>
                    </Details>
                    <ContinueButton message={message}/>
                </Form>
            </Wrapper>
        </Page>
    );
}
export default CharacterPage