import { useRef, useState, useEffect, useContext } from "react";
import { Tooltip } from 'react-tooltip'
import { CirclePicker } from 'react-color';

import { Theme, Page, Header, Navbar, NavigateBack, Wrapper, Form, GetUserInfo, Fetch, Loader, ServerCall } from "@files";

import "@css/pages/Preferences.scss";

function PreferencesPage(){
    const ref_controller = useRef({}), ref = ref_controller.current
    const [avatar, setAvatar] = useState(null)
    const [info, setInfo] = useState(null)
    const [name, setName] = useState(null)
    const [loaded, setLoaded] = useState(null)
    const [pageStyle, setPageStyle] = useState({})
    const theme = useContext(Theme)
    const [style, setStyle] = theme._style

    useEffect(() =>{
        async function fetchUserInfo() {
            try {
            const json = await GetUserInfo();
            setInfo(json.info);
            setPageStyle({...JSON.parse(json.info?.style)})
            setAvatar(json.info?.avatar ?? "https://i.ibb.co/KGxyQ8b/14.png")
            setName(json.info?.name ?? "Adventurer")
            } catch (error) {
            setInfo(error);
            }
        };
        fetchUserInfo();
    }, []);

    if(info===null) return <Loader/>
      
    async function handlePreferences(e, body) {
        e.preventDefault();
        setLoaded(null)
        // const form = e.target;
        // const formData = new FormData(form);
        // const formJson = Object.fromEntries(formData.entries());
        
        try{
            const json = await ServerCall({url: '/user', method: 'post', body: body})
            setStyle(JSON.parse(json.style) ?? {});
            setLoaded(<span id="profile-update-msg" className="updated">Successfully updated profile</span>)
            return await json;
        } catch(error) {
            setLoaded(<span id="profile-update-msg" className="error">Failed to update profile</span>)
            return error;
        }
    }
    const luma = (c) => {
        const rgb = c.rgb;
        const luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b; // per ITU-R BT.709
        return luma
    }
    function handleColor(c,) {
        const text_c = luma(c)<135 ? 'var(--light, #F4F4F4)' : 'var(--dark, #202020)'
        const newStyle = {};
        newStyle["--accent1"] = c.hex;
        newStyle["--accent1-color"] = text_c;
        setPageStyle({...pageStyle, ...newStyle})
    }

    const cssval = (v) => window.getComputedStyle(document.documentElement).getPropertyValue(v);

    return(
        <Page id="preferences" style={pageStyle}>
            <Header>
                <Navbar><NavigateBack/></Navbar>
            </Header>
            <Wrapper>
                <Form id="user-pref" method="post" onSubmit={(e) => handlePreferences(e, {
                    "name": name ?? info?.name ?? 'Adventurer',
                    "style": pageStyle ?? info?.style,
                    "avatar": avatar ?? info?.avatar ?? 'https://i.ibb.co/KGxyQ8b/14.png'
                })}>
                    <div id="user-pref-header">
                        <label htmlFor="set-avatar" id="user-avatar" style={{backgroundImage: `url(${avatar})`}}></label>
                        <input required={true} type="url" id="set-avatar" onChange={e=>setAvatar(e.target.value)} value={avatar} ref={el => ref['avatar-url'] = el} placeholder="Avatar URL"/>
                        <button id="update-avatar-btn" onClick={(e) => {
                            setAvatar(ref['avatar-url'].value);
                            handlePreferences(e, {
                                "name": info?.name ?? 'Adventurer',
                                "style": JSON.parse(info?.style),
                                "avatar": avatar ?? info?.avatar ?? 'https://i.ibb.co/KGxyQ8b/14.png'
                            });
                        }}>update avatar</button>
                        <Tooltip anchorSelect="#set-avatar">Accepts jpg, png, and gif formats</Tooltip>
                    </div>
                    <div id="user-info">
                        <label htmlFor="set-name"> Your name is 
                            <input required={true} type="text" id="set-name" onChange={e=>setName(e.target.value)} value={name ?? 'Adventurer'}/>
                        </label>
                        
                        <div id="theme-info">
                            <CirclePicker color={pageStyle['--accent1'] ?? cssval('--accent1')} onChange={handleColor}/>
                        </div>
                    </div>
                    
                    <div id="submission">
                        <button type="submit" id="submit-btn">save settings</button>
                        {loaded}
                    </div>
                </Form>
            </Wrapper>
        </Page>
    );
}

export default PreferencesPage