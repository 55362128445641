import { useRef } from "react";
import Description, { ShowDescription } from "@components/common/InputDescription";

/*
name: think of this as the group name, like backstory or personality traits

options: (an array with each entry as an object)
{
    type: text, textarea
    id:
    label:
    description:
    
    
    placeholder:
    minlength:
    maxlength:
    min:
    ... (refer to input attributes: https://www.w3schools.com/html/html_form_attributes.asp)
}
*/

function FieldInputs(args, o) {
    const { name } = args;
    const options = args.options ?? o;
    
    const result = [];
    const optionRef = useRef({});

    for(const e of options) {
        const attr = {
            type: e.type,
            id: e.id,
            name: name,
            placeholder: e.placeholder,
            minLength: e.minLength,
            maxLength: e.maxLength,
            min: e.min,
            max: e.max,
            step: e.step,
            pattern: e.pattern,
            disabled: e.disabled,
            readOnly: e.readOnly,
            value: e.value,
            onChange: e.onChange,
            cols: e.col,
            rows: e.rows
        }
        switch(e.type) {
            case ("textarea"):
                result.push(<>
                    <label htmlFor={e.id}>{e.label}</label>
                    <textarea onClick={() => ShowDescription({id: e.id}, optionRef)} {...attr}></textarea>
                </>)
            break;
            case ("text"):
            default:
                result.push(<>
                    <label htmlFor={e.id}>{e.label}</label>
                    <input onClick={() => ShowDescription({id: e.id}, optionRef)} {...attr}/>
                </>)
            break;

        }
    }

    return(
        <div className="field-inputs" id={name}>
            {result}
            {Description({name: name}, options, optionRef)}
        </div>
    )
}

export default FieldInputs;