import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Wrapper, Header, CharacterNavbar, Form, Button, ContinueButton, SelectionInputs, APICall, Loader } from '@files';

import '@css/pages/AbilityPage.scss';

function AbilityScoreForm() {
    // Load form data from sessionStorage
    const defaults = JSON.parse(sessionStorage.getItem('ability scores')) ?? { str: 10, dex: 10, con: 10, int: 10, wis: 10, cha: 10 };

    const [rollValue, setRollValue] = useState(null)
    const [message, setMessage] = useState(null)
    
    const navigate = useNavigate();

    const [proficiencies, setProficiencies] = useState(null)
    const [profLimit, setProfLimit] = useState(0)
    const className = sessionStorage.getItem('class');

    function getRandomRange(min, max){
        const range = max - min + 1
        return min + Math.floor(Math.random()*range)
    }

    const rollStats = (rollType) => {
        const s = [];
        switch (rollType) {
            case 'd20':
                //just straight roll a d20
                for(let i = 0; i < 6; i++){
                    s.push(getRandomRange(1,20))
                }
                setRollValue(s)
                break;
            case '4d6':
                //roll 4d6, take 3 highest rolls
                for(let i = 0; i < 6; i++){
                    //calc roll
                    let r1 = getRandomRange(1,6)
                    let r2 = getRandomRange(1,6)
                    let r3 = getRandomRange(1,6)
                    let r4 = getRandomRange(1,6)
                    let sum = r1 + r2 + r3 + r4
                    sum -= Math.min(Math.min(r1, r2),Math.min(r3, r4))
                    s.push(sum)
                }
                setRollValue(s)
                break;
            case '3d6':
                //roll 3d6
                for(let i = 0; i < 6; i++){
                    //calc roll
                    let sum = 0
                    for(let j = 0; j < 3; j++){
                        sum += getRandomRange(1,6)
                    }
                    s.push(sum)
                }
                setRollValue(s)
                break;
            case '2d6 + 6':
                //roll 3d6
                for(let i = 0; i < 6; i++){
                    //calc roll
                    let sum = getRandomRange(1,6) + getRandomRange(1,6) + 6
                    //add to output
                    s.push(sum)
                }
                setRollValue(s)
                break;
            default:
                setRollValue(null)
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        delete formJson['prof']
        console.log(formData.getAll('prof').length)
        if( (formData.getAll('prof')?.length ?? 0) < profLimit){
            setMessage(<div className="error">Please fill out proficiencies</div>)
            return false
        }
        if( (formData.getAll('prof')?.length ?? 0) > profLimit){
            setMessage(<div className="error">Too many selections! Max of {profLimit} proficiencies</div>)
            return false
        }
        sessionStorage.setItem('ability scores', JSON.stringify(formJson))
        //saving proficiencies to session storage
        const profJson = formData.getAll("prof")
        sessionStorage.setItem("skill proficiencies", JSON.stringify(profJson))
        //redirect to next page (wherever that is)
        setMessage(<div className="updated">Saved</div>)
        navigate('/spellcasting')
    }

    useEffect(()=>{
        ['character name', 'class', 'race', 'background'].forEach((e) => {
            if(sessionStorage.getItem(e)===null) {
                navigate('/character')
            }
        })
        async function getProfs() {
            try {
                const profs = [];
                const data = await APICall("/api/classes/" + className.toLowerCase());
                const classProfs = data.proficiency_choices[0].from.options
                setProfLimit(data.proficiency_choices[0].choose)
                for(let i = 0; i < classProfs.length; i++){
                    profs.push({
                        'index': classProfs[i].item.index,
                        'name': (classProfs[i].item.name).substring(7),
                        'url': classProfs[i].item.url,
                    })
                }
                //update hook with all spells
                setProficiencies(profs)
            } catch (error) {
                console.log(error)
                setProficiencies(error)
            }
        }
        getProfs();
    }, [])

    if(proficiencies===null) return <Loader/>

    const loadedprofs = JSON.parse(sessionStorage.getItem('skill proficiencies')) ?? null

    return (
        <Page id="ability">
            <Header><CharacterNavbar/></Header>
            <Wrapper>
                <Form onSubmit={handleSubmit}>
                    <div className="ability-container" id="set-scores">
                        <h1>Set Ability Scores</h1>

                        {['strength', 'dexerity', 'constitution', 'intelligence', 'wisdom', 'charisma'].map(score=>
                            <div className="abilityscore">
                                <input required={true} {...{type: 'number', placeholder: score.toUpperCase() + " SCORE", defaultValue: defaults[score.slice(0,3)], id: score.slice(0,3), name: score.slice(0,3), min: 1, max: 20}}/>
                                <label htmlFor={score.slice(0,3)}>{score}</label>
                            </div>
                        )}

                        <div id="roll-btns">
                            <Button type="button" onClick={() => rollStats('d20')}> d20 </Button>
                            <Button type="button" onClick={() => rollStats('4d6')}> 4d6 (drop lowest) </Button>
                            <Button type="button" onClick={() => rollStats('3d6')}> 3d6 </Button>
                            <Button type="button" onClick={() => rollStats('2d6 + 6')}> 2d6 + 6 </Button>

                            {rollValue && 
                            <div id="roll-result">
                                <div className="rollresult" id="roll-numbers">Results: {rollValue.join(', ')}</div>
                                <div className="rollresult" id="roll-total">Total: {rollValue.reduce((acc,curr)=> acc+curr, 0)}</div>
                            </div>}
                        </div>
                    </div>
                    <div className="ability-container" id="set-proficiency">
                        <h1>Select {profLimit} Proficiencies</h1>

                        <div className="proficiency">
                            <SelectionInputs type="checkbox" name="prof" max={profLimit} options={proficiencies.map(e=>({
                                id: e.index, label: e.name, value: e.name, checked: loadedprofs ? loadedprofs?.indexOf(e.name)>-1 : false
                            }))}/>
                        </div>
                    </div>
                    <ContinueButton message={message}/>
                </Form>
            </Wrapper>
        </Page>
    );
};

/*
<div className="inner-select">
    <SelectionInputs type="checkbox" name="1" max={maxSpells[1]} options={spellList[1].map(e=>({
        id: e.index, label: e.name, description: e.description, value: e.name, checked: loadedspells ? loadedspells['1']?.indexOf(e.name)>-1 : false
    }))}/>
</div>
*/

export default AbilityScoreForm;
