import React, { useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Logout, Navbar } from '@files';

import "@css/shared/HomeNavbar.scss";

function HomeNavbar({avatar}) {
    const showRef = useRef({})
    const navigate = useNavigate()

    function handleNew() {
        sessionStorage.clear()
        navigate('/character')
    }

    return (
        <Navbar>
            <div className="avatar" onClick={()=>{
                const btn = showRef.current['btn'], hide = btn.classList.contains("hide");
                console.log(btn.children);
                const width = showRef.current['text'].offsetWidth;
                    btn.classList.toggle('hide');
                    btn.style.width = (hide ? 0 : width) + "px"
            }}>
                <img src={avatar}/>
            </div>
            <div id="btns-group" ref={el=>showRef.current['btn']=el}>
                <span ref={el=>showRef.current['text']=el}>
                    <button id="edit-btn" onClick={()=>{navigate("/settings")}}>
                        <span>edit profile</span>
                    </button>
                    <button id="logout-btn" onClick={Logout}>
                        <span>logout</span>
                    </button>
                </span>
            </div>
            
            <button onClick={handleNew}>create a character</button>
        </Navbar>
    )
}

export default HomeNavbar;