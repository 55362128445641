import { useState } from 'react';
import '@css/components/Page.scss'

function Page(args) {
    const { children, id, style } = args;

    return(
        <div className="page" id={id} style={style}>
            {children}
        </div>
    )
};

export default Page;