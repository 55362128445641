/* 
args = {
    id:
    onSubmit:
    content:
    method:
    onSubmit:
    submitValue: (submit button)
}
*/

import Button from "@components/common/Buttons";
import '@css/components/Form.scss';

function Form(args) {
    const {id, onSubmit, submitValue, children, method} = args;
    return(
        <form className="form-element" id={id} onSubmit={onSubmit} method={method}>
            {children}
            {submitValue&&<Button className="form-submit-btn" type="submit">{submitValue??"Submit"}</Button>}
        </form>
    );
}

export default Form;